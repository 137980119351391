import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "versailles",
  casinoId: 69,
  GA_TRACKING:'G-8DGXY6XPDH',

  rivalChatGroupName: "Versailles",
  prettyName: "Versailles Casino",
  contactEmail: "support@versaillescasino.com",
  docsEmail: "documents@versaillescasino.com",

  //SEO
  metaDescription: "Join Versailles Online Casino and Play 300+ slots, live dealer games, table games like blackjack, roulette, and baccarat. Gamble with bitcoin and crypto, wub VIP rewards and enjoy fast payouts. Play now for real cash wins!",
  logoName: "versailles-casino-online-casino-logo.png",
  logoAltText: "Versailles Casino online logo with gold fleur-de-lis symbol and elegant font popular for European-themed slots table games and exclusive casino rewards."
};


